import Spinner from '@/common/pages/spinner';
import { useRouter } from 'next/router';

const Home = () => {
  const router = useRouter();
  router.push('/app/dashboard');
  return <Spinner />;
};

Home.auth = true;
export default Home;
